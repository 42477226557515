.panier-contribution {
    opacity: 1;
    visibility: visible;
    position: relative
}


/* .input-range__track.input-range__track--active + span {
    display: none;
} */
.panier-contribution .col-md-12 .adresse-paiment .produits .price{
    color: #000000;
    font-size: 13px;
    font-family: "TWKLausanne-600";
}

header .top-header .top-header-right * {
    display: inline-block;
}

header .top-header .top-header-right .account-choice{
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    width: 102px;
    background: #000000;

}
header .top-header .top-header-right .account-choice a
{
    border: none;
    text-indent: 0;
    display: block;
}

header .top-header .top-header-right .account-choice li
{
    display: block;
}

header .top-header .top-header-right .account-user{
    position: relative;
}

header .top-header .top-header-right .account-user:hover .account-choice
{
    display: block;
}
.items-last-add .items {
    /* position: initial; */
    /* z-index: 0; */
    width: 100%;
}
/* checkout */
/* .adresse-paiment .connect-content .joint-club,
.nom-account, .adresse-livraison,
.ajout-adresse, .form-adresse,
.moyen-paiement, .form-adresse,
.connect-content:last-child, .nom-account, .nom-account  {
    display: none
} */

.page-login .formulaire-login {
    padding-top: 0;
}


button.cheekout {
    width: 210px;
    height: 36px;
    line-height: 36px;
    color: #000000;
    font-size: 13px;
    font-family: "TWKLausanne-600";
    border: none;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    background: #ffffff;
    cursor: pointer;
    margin-left: 12px;
}
button.cheekout[disabled], .panier-contribution .adresse-paiment .adresse .connect-content .identification .form-submit input[disabled] {
    background-color: #e2dbdb;
    cursor: not-allowed;
}
#customers {
    font-family: "Trebuchet MS", Arial, TWKLausanne-600, sans-serif;
    border-collapse: collapse;
    width: 100%;
    font-size: 14px
  }

  #customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
  }
    #customers tr td {
        color: white;
    }
  #customers tr:nth-child(even) td {background-color: #f2f2f2; color: #000000}

  #customers tr:hover {background-color: #ddd;}

  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #7e847e;
    color: white;
  }

  .panier-contribution .breadcrumbs {
    display: none;
}
.panier-contribution {
    background: black;
}
#last-addition-shop .slick-current > div {
    min-height: 300px;
}
#last-addition-shop .slick-current > div .spinner {
    position: absolute;
    margin: auto;
    left: 620px;
}
.top-header-right > * {
    cursor: pointer;
}
.page-login.register {
    visibility: visible;
    opacity: 1;
    position: relative;
}
.btn-heart{
    width: 36px;
    height: 36px;
    background: none;
    display: block;
    float: right;
    border: none;
    border-radius: inherit;
    cursor: pointer;
    position: relative;
}
.btn-heart img {
    position: absolute;
    top: 0;
    left: 0;
    /* top: 50%;
    left: 50%;
    margin-top: -8px;
    margin-left: -8px; */
}
 /* .btn-heart.like {
    background-image: url(./assets/images/heart-like.png);
} */

/* .btn-heart.dislike {
    background-image: url(./assets/images/heart-dislike.png);
}  */

.favorites .items-last-add .items {
    position: relative;
    z-index: 0;
    height: 407px;
    width: 24%;
    display: inline-block;
    margin: 15px 0px;
    padding: 0 5px;
}
.clearfix:before,
.clearfix:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}

.clearfix:after {
  clear: both;
}

/*
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */
.clearfix {
  zoom: 1;
}
body.no-sroll {
    overflow: hidden;
}
header .top-header .top-header-right img {
    width: 17px;
}
.user-view {
    display: inline-block;
    height: 36px;
    border-radius: 15px;
    padding: 4px;
    background-color: transparent;
    cursor: pointer;
}

.delete {
    position: absolute;
    left: 10px;
    top: 10px;
    background-color: transparent;
    border: 0;
    cursor: pointer;
}
.panier-contribution .adresse-paiment .adresse .adresse-livraison .list-adresse .list + .list {
    margin-top: 10px;
}
.panier-contribution .adresse-paiment .adresse .adresse-livraison .list-adresse .list {
    background: transparent;
    display: block;
    width: 100%;
    text-align: left;
}
.panier-contribution .adresse-paiment .adresse .adresse-livraison .list-adresse .list {
    background: transparent;
    display: block;
    width: 100%;
    text-align: left;
    cursor: pointer;
}
.panier-contribution .adresse-paiment .adresse .sauvegarder-adresse {
    float: left;
    padding-top: 0px;
}
.panier-contribution .adresse-paiment .adresse .sauvegarder-adresse button{
    width: 180px;
    height: 30px;
    line-height: 31px;
    display: block;
    font-size: 12px;
    color: #ffffff;
    /* padding-left: 20px; */
    position: relative;
    background: #3d3d3d;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    float: right;
}

button, a {
    cursor: pointer;
}
header .select {
    position: relative;
    border: 1px solid white;
    float: left;
    width: 59px;
    border-radius: 28px;
    height: 37px;
}

header .select select {
    height: 100%;
    font-size: 13px;
    color: white;
    line-height: 14px;
    letter-spacing: -0.1px;
    padding: 5px 10px;
    display: block;
    border: 0;
    background: none;
    width: 100%;
    -webkit-appearance: none;
}
header .select:before {
    position: absolute;
    right: 10px;
    top: 34%;
    content: "↓";
    pointer-events: none;
    color: white;
}
header .dark .select {
    border: 1px solid black;
}
header .dark  .select select {
    color: black;
}
header .dark .select:before {
    color: black;
}
.logo-footer img {
    width: 180px;
    height: 40px;
    margin: 10px auto;
    display: inline-block;
}
.display-inline-block {
    display: inline-block;
    vertical-align: top;
}
.text-center {
    text-align: center;
}
.articles {
    text-align: left;
}
.btn-show-more button {
    font-size: 13px;
    line-height: 14px;
    letter-spacing: -0.22px;
    color: #000000;
    background: transparent;
    border: 0;
}
.last-addition-shop .menu-addition {
    width: 100%;
    height: 170px;
    background-size: cover;
    text-align: center;
    padding-top: 16px;
    margin-bottom: 8px;
    text-transform: uppercase;
}
.products-wrapper {
    height: 100%;
    position: relative;
}
/* .products-wrapper .modal-body.quick-purchase {
    top: 50%;
} */
.details-info .option-gift .licenses label {
    cursor: pointer;
}
.adresse .details-info .option-gift .licenses label{
    height: 170px;
    border: 1px solid #3c3c3c;
    border-radius: 0;
    background: transparent;
    width: 100%;
    line-height: 25px;
    padding: 10px 20px;

}
.adresse .details-info .option-gift .licenses .item{
    height: 170px;
    margin-bottom: 15px;

}
.adresse .details-info .option-gift .licenses label span{
    display: block;
    color: white;
    margin: 0;
}
.adresse .details-info .option-gift .licenses input:checked + label {
    border: 3px solid white;
    border-radius: 7px;
}
.adresse .details-info .option-gift .licenses input:checked + label span{
    font-weight:bold;
}

.panier-contribution .adresse-paiment .produits .list-produit .container h3.card-title {
    float: none;
    display: block;
}

.panier-content .content-panier .content .short-text {
    font-size: 12px;
    line-height: normal;
    margin-top: 5px;
    display: block;
}
.panier-contribution .adresse-paiment .produits .list-produit .images {
    width: 60%;
    float: left;
    margin-top: 20px;
    margin-left: 13px;
}
.panier-contribution .adresse-paiment .produits .list-produit .container {
    padding: 20px 5px 0 10px;
}
.panier-contribution.orders .adresse-paiment .produits .total-paiement {
    position: relative;
    display: block;
    margin-top: 15px;
}

.panier-contribution.orders .adresse-paiment .produits {
    width: 50%;
    float: right;
    position: relative;
    padding-bottom: 0;
}


.item {
    display: inline-block;
    vertical-align: middle;
    margin-right:15px;
    width: 200px;
    position: relative;
}
.item * {
    transition: 1s;
}
.editButton {
    display: inline-block;
}
.edit {
    width: 50px;
    height: 30px;
    display: block;
    font-size: 12px;
    color: #ffffff;
    padding: 10px;
    background: #3d3d3d;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
}
.list-adresse {
    height: 682px;
    overflow: scroll;
}
.panier-contribution.panier-content.orders {
    padding: 0;
}

.panier-contribution.panier-content.orders .checkout {
    bottom: 4px  !important;
}
.panier-contribution.panier-content.orders .prev {
    position: absolute;
    bottom: 40px;
    margin-left: 30px;
}

.panier-contribution.panier-content.orders .next {
    position: absolute;
    bottom: 40px;
    margin-left: 0px;
    right: 30px;
    background: #000000 !important;
    color: #ffffff;
    z-index: 99;
}

.orders .panier-contribution .adresse-paiment .adresse .adresse-livraison {
    padding: 0;
}

.panier-contribution .adresse-paiment .adresse .adresse-livraison .list-adresse p {
    display: block;
    float: none;
}
.orders .details-info .option-gift {
    padding-top: 0px;
    margin-bottom: 30px;
}

.orders .adresse-paiment.container-center {
    padding: 40px 30px
}
.orders.panier-contribution .adresse-paiment .adresse .ajout-adresse.action {
    padding-top: 0;
    width: auto;
    float: none;
    position: absolute;
    top: 0;
    right: 0;
}

.orders.panier-contribution .adresse-paiment .adresse .adresse-livraison {
    width: 100%;
    float: left;
    padding: 0;
}
/* .orders.panier-contribution .adresse-paiment .adresse .connect-content {
    position: relative;
} */
.check-icon {
    position: absolute;
    right: -11px;
    top: -7px;
}
.checked-icon {
    position: absolute;
    top: -7px;
}
.adresse .details-info .option-gift .licenses .item .adresse-layer {
    height: 0;
}
.adresse .details-info .option-gift .licenses .item .adresse-layer * {
    opacity: 0;
}

.adresse .details-info .option-gift .licenses .item:hover .adresse-layer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background: rgba(255, 255, 251, 0.3);
    height: 170px;
}
.adresse .details-info .option-gift .licenses .item:hover .adresse-layer * {
    opacity: 1;
}

.adresse .details-info .option-gift .licenses .item:hover label {
    opacity: 0.4;
}
.adresse .details-info .option-gift .licenses .item .action button {
    background: #3d3d3d;
    width: 120px;
    padding: 5px 10px;
    border-radius: 24px;
    color: white;
    border-color: white;
    font-size: 11px;
}
.adresse .details-info .option-gift .licenses .item .action button + button{
    margin-top: 10px;
}
.adresse .details-info .option-gift .licenses .item .action {
    position: absolute;
    top: 50%;
    margin-top: -64px;
    text-align: center;
}

.popup-recherche  img{
    /* float: right; */
    cursor: pointer;
    margin: 10px 10px 0px 0px
 }
 .react-autosuggest__container {
    position: relative;
    height: 640px;
  }

  .react-autosuggest__input {
    width: 100%;
    font-size: 36px;
    line-height: 36px;
    letter-spacing: -0.28px;
    text-align: center;
    color: #ffffff;
    padding-bottom: 5px;
    background: none;
    border: 0;
    border-bottom: 1px solid #ffffff;
  }

  .react-autosuggest__input:focus {
    outline: none;
  }

  .react-autosuggest__container--open .react-autosuggest__input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__container--open .react-autosuggest__suggestions-container {
    display: block;
    position: absolute;
    top: 51px;
    width: 100%;
    /* border: 1px solid #aaa; */
    font-family: TWKLausanne-600, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;

  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    height: 600px;
    overflow: scroll;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding-left: 20px;
  }

  /* .react-autosuggest__suggestion:not(:first-child) {
    border-top: 1px solid #ddd;
  } */

  .react-autosuggest__suggestion--focused {
    background-color: #0C7EAF;
    color: #000;
  }

  .suggestion-content {
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
  }
  .name {
    margin-left: 68px;
    line-height: 45px;
  }

.highlight {
  color: #ee0000;
  font-weight: bold;
}
.light {
    color: #000;
  }

.react-autosuggest__suggestion--focused .highlight {
  color: #120000;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 99;
}
.closeModal {
    background: transparent;
    border: 0;
    position: absolute;
    top: 0px;
    right: 0px;
}
.table-detail,.table-detail th,.table-detail  td {
    border: 1px solid #ddd;
    border-collapse: collapse;
  }
  .table-detail th,.table-detail td {
    padding: 10px;
    text-align: left;
    background-color: #f2f2f2;
    font-family: "Trebuchet MS", Arial, TWKLausanne-600, sans-serif;
    color: black
  }
  .table-detail th {
    background-color: #002140;
    color: white;
    width: 144px;
  }
  .modal-view .ant-modal-footer .ant-btn-primary {
     display: none
  }
  .btn-eye {
    display: inline-block;
    margin-left: 5px;
    color: #6969e6;
  }
  table.table-detail {
    width: 100%;
  }
  .col-md-6.float-left .list
   {
    height: 170px;
    border: 1px solid #3c3c3c;
    border-radius: 0;
    background: transparent;
    width: 95%;
    line-height: 25px;
    padding: 10px 20px;
    margin: 10px;
   }
   .col-md-6.float-left .list p {
    font-size: 20px;
    line-height: normal;
   }
   .failed {

    font-size: 36px;
    text-align: center;
    margin-bottom: 10px;
    color:#ddd;
   }
   .goBack a{

    width: 200px;
    height: 36px;
    line-height: 36px;
    display: block;
    text-align: center;
    font-size: 13px;
    background: #ffffff  no-repeat 10px center;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    margin: 8px auto;
    cursor: pointer;
    color:#000;
   }
   .message-failed {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
   }
   .payment-failed{
    background-color:#000 ;
    height: 100vh;
    width: 100%;
    padding: 20px;
    }



.wrapper {
    display: block;
    text-align: center;
    padding: 80px 15px 30px;
    height: 100vh;
}

.simple-table {
    border: 1px solid rgb(204, 204, 204);
    margin: 0px;
    padding: 0px;
    width: 100%;
    table-layout: fixed;
    font-size: 14px;
}

.has-table {
    padding-right: 30px;
}
.table-vertical {
    width: 100%;
    border: 1px solid #ccc;
    font-size: 14px;
    table-layout: auto;
    margin-top: 20px;
    padding: 5px 23px;
}
.payment {
    padding: 24px 5px;
}
.thumbnail-checkout {
    max-width: 100%;
    margin: 0% 0 0 3%;
    width: 137px;
}
.thumbnail-checkout img {
    width: 100%;
}

#root .img-home-top .img {
    background-size: cover;
    background-position: top;
}

.img-view img {
    width: 100%;
}

p.info-bulle {
    position: absolute;
    background-color: rgba(21, 21, 21, 0.89);
    top: -3px;
    font-size: 11px !important;
    width: 130px !important;
    line-height: 12px !important;
    padding: 8px 7px;
    height: auto !important;
    border-radius: 20px;
    color: #f1f1f1 !important;
    left: -137px;
    transform:scale(0) rotate(-12deg);
}
p.info-bulle:before {
    content: '';
    position: absolute;
    top: 15px;
    right: -8px;
    width: 0;
    height: 0;
    border-bottom: 6px solid rgba(21, 21, 21, 0.89);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    transform: rotate(86deg);

}
.qty.disabled:hover  p.info-bulle {
    transform:scale(1) rotate(0);
}
.qty.disabled button.increment {
    pointer-events: none;
}
.details-info .qty-add-favori .qty {
    position: relative;
}
.in-Search {
    display: inline-block
}

.page-404 {
    height: 100vh;
    background: black;
    color: white;
    text-align: center;
    padding-top: 15vh;
}
.page-404-title {
    margin: auto;
    font-size: 220px;
    line-height: 1.09;
    letter-spacing: 5px;
    margin-bottom: 20px;
}
.page-404 * {
    color: white;
}
.page-404-sub-title {
    margin: -12px auto 35px;
    font-size: 66px;
    font-weight: normal;
    line-height: 1.55;
    letter-spacing: normal;
}
.page-404-text {
    margin: auto;
    width: 678px;
    opacity: 0.8;
    font-size: 28px;
    line-height: normal;
    letter-spacing: normal;
}
.articles .description .category-link .see-more span {
    float: left;
    visibility: visible;
    color: black;
    opacity: 1;
}
#last-addition-shop.last-addition-shop .items-last-add .items figure img {

    width: 100%;
}
.form-mes-infos button {
    width: 185px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    float: left;
    color: #ffffff;
    font-size: 13px;
    background: #000000;
    border: 0;
    margin: 10px 0 20px;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    cursor: pointer;
}
.modal-body .footer-modal .details-info {
    min-height: auto;
}
.modal-body .details-info {
    min-height: 200px;
}
.favorites .last-addition-shop .items-last-add .items figure img {
    height: 248px;
    width: 100%;
}
.react-autosuggest__suggestions-list li {
    float: left;
    width: 33%;
}
.in-Search {
    display: inline-block;
    width: 100%;
}
.popup-recherche a {
    display: block;
    position: relative;
}
.short-story .font-style .content-style p {
    font-size: 36px;
    line-height: normal;
    letter-spacing: 5px;
}

/* .short-story .font-style .premier .content-style p {
    font-size: 240px;
    line-height: 200px;
    letter-spacing: 5px;
} */

/* .short-story .font-style .deuxieme .content-style p {
    font-size: 60px;
    line-height: 62px;
    letter-spacing: 5px;
} */
/* .short-story .font-style .troisieme .content-style p {
    font-size: 130px;
    line-height: 124px;
    letter-spacing: 5px;
} */
.list-licenses .text-bottom time {
    color: #c4c4c4;
}

p.features {
    width: 30px;
    height: 30px;
    border: 2px solid #000;
    border-radius: 100%;
    margin: 7px 7px 7px 0px;
    display: inline-block;
    vertical-align: middle;

}
p.features-size {
    display: inline-block;
    vertical-align: middle;
    border: 3px solid #000;
    padding: 0px 5px;
}
.panier-content .content-panier .content p.features-size {
    color: black;
}

.monnaie-chf.disabled {
    opacity: .4;
}

.modal-body.quick-purchase {
    min-height: 315px;
}
.default-btn-cart {
    width: 210px;
    height: 36px;
    line-height: 36px;
    display: block;
    color: #000000;
    font-size: 13px;
    text-align: center;
    font-family: "TWKLausanne-600";
    border: none;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    background: #ffffff;
}
.popup-license-form.open {
    z-index: 9;
}
.details-info .option-gift label {
    cursor: pointer;
}
.panier-content .content-panier .content .information {
    float: left;
    margin-right: 19px;
}
#root .panier-content.orders .prix {
    width: 100%;
    float: left;
}
/* .view-layout-4 .articles .img-l-full {
    display: block;
    height: 330px;
}
.view-layout-2 .articles .img-l-full {
    position: relative;
    display: block;
    height: 400px;
}
.view-layout-2 .img-l-full img {
    height: 100%;
} */
.filtre-produits  .col-md-6 {
    width: 50% !important;
}
.list-typeface.press label {
    color: black;
    font-size: 13px;
    font-family: "Lausanne400";
    text-align: center;
    cursor: pointer;
    background: #ebebeb;
    font-weight: 200;
}
.list-typeface.press input:checked + label {
    color: white;
    background: black;
}
#related .padding-l-r-4 {
    padding-left: 4px !important;
    padding-right: 4px !important;
    width: 25%;
    margin-bottom: 8px;
}
#related .padding-l-r-4 .d-flex-column-c {
    height: 328px;
}
#related .padding-l-r-4  .img-h-full img {
    width: 100%;
    height: 300px;
    display: block;
    margin: 0 auto;
}
.price-items {
    display: block;
}
.details-info .license-third a.checked:before {
    background-color: #000;
}
/*#about .col-md-6 {
    width: 50%;
}*/

.panier-contribution .adresse-paiment .adresse .connect-content .identification .form-submit button {
    width: 210px;
    height: 36px;
    line-height: 36px;
    color: #000000;
    font-size: 13px;
    font-family: "TWKLausanne-600";
    border: none;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    background: #ffffff;
    cursor: pointer;
}
.panier-contribution .adresse-paiment .adresse .connect-content .identification .form-text .password-forget {
    color: #4A4A4A;
}

.orders.panier-contribution .adresse-paiment .adresse .connect-content {
    width: 80%;
}
.reset-password {
    background: black;
    height: 100vh;
}
.simple-form {
    width: 83%;
    position: relative;
    margin: auto;
    padding-top: 22vh;
    text-align: center;
    color: white;
}

.simple-form .back a p{
    font-size: 13px;
}
.simple-form .back {
    margin-top: 5px;
    position: absolute;
    z-index: 9999;
    top: 0;
    color: #000000;
    font-family: 'TWKLausanne-600';
    background: url(assets/images/btn-back-b.svg) no-repeat left center;
    padding-left: 20px;
    display: flex;
}

.label {
    color: white;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
    display: block;
}
.simple-form .form-text input {
    width: 52%;
    height: 47px;
    background-color: transparent;
    border: 0 transparent;
    border-bottom: 1px solid #fff;
    margin-bottom: 50px;
    color: white;
}


.simple-form .form-submit button {
    width: 30%;
    height: 36px;
    line-height: 36px;
    color: #000000;
    font-size: 13px;
    font-family: "TWKLausanne-600";
    border: none;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    background: #ffffff;
    cursor: pointer;
}
#root .panier-content.orders .prix {
    width: 61%;
    float: left;
    text-align: right;
}
.panier-content.orders .content-panier .content * {
    color: #000000;
    font-size: 13px;
    display: inline-block;
}

.panier-content.orders .content-panier .content .information {
    width: 35%;
}
.wraper-info {
    width: 100%;
}
.panier-contribution.orders .adresse-paiment .produits .list-produit .container .prix span {
    float: none;
}
.panier-content.orders .content-panier .content .price-items {
    display: block;
}
.panier-contribution.orders .adresse-paiment .produits .total-paiement .total {
    width: 38%;
}
.panier-content .header-panier {
    margin-bottom: 12px;
}

.panier-content.orders  .content-panier {
}

.panier-content .content-panier .overview{
    overflow-y: auto;
    position: relative;
}
.panier-content {
    top: 36px;
}
.panier-content.show-boxes {
    top: 79px;
}

.pagination-produits ul li.active a {
    background: black;
    color: white;
}
/* .panier-contribution.panier-content.orders {
    height: 100vh;
} */
.col-md-12 .user-view span {
    color: #000;
}
.top-header-right .user-view span {
    color: #fff;
}
.dark .user-view span {
    color: #000;
}
.dark .user-view {
    border-color: #000000;
}

.panier-content.orders {
    top: 0;
}

/* scroll top */
App {
    text-align: center;
    height: 5000px;
  }

.scrollTop {
    position: fixed;
    bottom: 126px;
    align-items: center;
    height: 30px !important;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.5;
    left: 96.2%;
    font-size: 25px;
}

.scrollTop:hover{
opacity: 1;
}

@keyframes fadeIn {
0% {
  opacity: 0;
}
100% {
  opacity: 0.5;
}
}

.footerLast span {
    color: #EBEBEB;
}

.wa-chat-box-brand-name {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: #fff;
}

.wa-chat-box-brand-subtitle {
    font-size: 13px;
    line-height: 18px;
    margin-top: 4px;
    color: #FFF;
}

.wa-chat-box-poweredby {
    display: none;
}
@media screen and (max-width: 480px) {
    #related .padding-l-r-4 {
        padding-left: 0px !important; 
       padding-right: 0px !important;
   
    }

    .simple-form {
        width: 100%;
    }
}
