.modal-wrapper {
    background: rgba(21, 20, 20, 0.56);
    border: 1px solid #d0cccc;
    box-shadow: 0 5px 8px 0 rgba(0,0,0,0.2), 0 7px 20px 0 rgba(0,0,0,0.17);
    margin: 0 auto 0;
    transition: all .8s;
    position: fixed;
    top: 0;
    z-index: 9;
    left: 0;
    height: 100%;
    top: 0;
    width: 100%;
    
}
.modal-wrapper.inactive {
    opacity: 0;
    z-index: -1;
}
.modal-wrapper.active {
    opacity: 1;
}

.modal-header {
    background: #101010;
    height: 40px;
    line-height: 40px;
    padding: 5px 20px;
    text-align: right;
}

.modal-header h3 {
    color: white;
    float: left;
    margin: 0;
    padding: 0;
}

.modal-content {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    height: 100%;
    color: white
}
.modal-content p {
    color: white
}

.modal-footer {
    background: #263238;
    height: 35px;
    padding: 15px;
}

.close-modal-btn {
    cursor: pointer;
    float: right;
    font-size: 32px;
    margin: 0;
    position: absolute;
    right: 9px;
    top: 0;
    z-index: 1;
    color: black;
}

.close-modal-btn:hover {
    color: black;
}

.btn-cancel, .btn-continue {
    background: coral;
    border: none;
    color: white;
    cursor: pointer;
    font-weight: bold;
    outline: none;
    padding: 10px;
}

.btn-cancel {
    background-color: #b71c1c;
    float: left;
}

.btn-continue {
    background-color: #1b5e20;
    float: right;
}

.back-drop {
    background-color: rgba(48, 49, 48, 0.42);
    height: 100%;
    position: absolute;
    transition: all 1.3s;
    width: 100%;
    top: 0;
    left: 0;
}

.open-modal-btn {
    margin: 15px;
    padding: 10px;
}


.modal-wrapper .qty {
    padding: 8px 0;
    display: block;
    width: 92px;
    margin: 0 auto;

}
.modal-wrapper .add-qty {
      width: 90px;
      height: 36px;
      padding: 8px 10px;
      border: 1px solid #c4c4c4;
      border-radius: 25px;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
}
.modal-wrapper .add-qty button, .modal-wrapper .add-qty p{
    display: inline-block;
    text-align: center;
}
.modal-wrapper .add-qty button {
    width: 20%;
    border: 0;
    cursor: pointer; 
    background: transparent;
    color: white;
}
.modal-wrapper .add-qty p{
    width: 50%;
}
.btn-view-detail {
    display: block;
    width: 147px;
    margin: 22px auto;
    color: white;
    background: #FF0032;
    height: 37px;
    line-height: 37px;
    border-radius: 17px;
    font-size: 15px;
    font-weight: bolder;
}
.modal-body {
    text-align: center;
    display: block;
    position: relative;
    width: 100%;
}
.col {
    display: inline-block;
    padding: 0 15px;
    vertical-align: middle;
}
.col-md-6 {
    width: 49%;
}

.col.white {
    background-color: white;
    text-align: left;
    padding: 12px;
}
.col.dark {
    background-color: black;
    height: 100%;
    padding: 12px;
}
.modal-content .white p {
    color: black;
}
.modal-content .overview {
    height: 29vh;
    overflow: scroll;
}
.modal-content .white .light-text {
    color: #707070;
    font-size: 13px;
    text-transform: capitalize;
}
.modal-title {
    font-size: 14px;
    color: black;
}
.modal-body .details-info .col-md-12 {
    padding-top: 3px;
}
.footer-modal {
    box-shadow: 0px -6px 10px -7px #000000;
    padding-top: 12px;
}
.footer-modal button {
    cursor: pointer;
}
.quick-purchase {
    width: 715px;
}
.modal-body.quick-purchase {
    position: absolute;
    top: 18%;
    left: 50%;
    margin-left: -357px;
    background-color: white;
}
.modal-body .details-info {
    padding: 0;
    height: 75%;
}
.modal-body .footer-modal.details-info {
    height: auto;
}

.modal-body .details-info .option-gift {
    padding-top: 16px;
    overflow: scroll;
    height: 85%;
}
.modal-body .monnaie-welt {
    float: right;
    opacity: 0;
}
.modal-body .monnaie-chf {
    float: left;
}
.slide-range {
    margin-top: 12px;
    opacity: 0;
}